import { Card, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import AddOrEditCompany from './AddOrEditCompany'
import ViewCompany from './viewCompany'

const Company = (props) => {
    const [selectedTab, setSelectedTab] = useState("add-Company")
    const [ editData, setEditData ] =useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
      };
  return (
    <AppBase
    metaDetails={{
      title: 'Company',
      // subtitle: '',
      // description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
    }}
    >
        <Card>
            <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                <TabPane tab="Company" key="add-Company">
                    {selectedTab ==="add-Company"? (
                        <AddOrEditCompany
                        editData={editData}
                        key={'add-Company'}
                        onSelectTab={onSelectTab}
                        {...props}/>
                    ):
                    null}
                </TabPane>

                <TabPane  tab='View Company' key='view-Company'>
              {selectedTab === 'view-Company' ? (
                <ViewCompany onSelectTab={onSelectTab} {...props} />
              ) : null}
            </TabPane>
            </Tabs>
        </Card>
    </AppBase>
  )
}

export default Company