import { Button, Form, Input, Space, Spin} from 'antd'
import React, { useState } from 'react'
import { STATE } from '../../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../../constants/hardData'
import {  postAPI } from '../../../../../utils/apiRequest'
import { displayMessage } from '../../../../../utils/common'

const AddOrEditState = (props) => {

  const [loading, setLoading] = useState(false)

 

  
  
  

 

  

  const onFinish = (values) => {
    let {editData} = props;
    setLoading(true)
    let reqData = {...values}

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-state')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'State Updated Successfully.');
      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'State Added Successfully.');
      }
    }
    let errorFn = function(error){
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(STATE, reqData, successFn, errorFn);
    }
    else{
    postAPI(STATE, reqData, successFn, errorFn)
    }
  }
  
  const handleCancelEdit = () => {
    props.onSelectTab("view-state");
  }
  
  
  return (
   
        <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
          }}
        >
          
          <Form.Item
            label={'State'}
            name={'name'}
            rules={[{ required: true }]}
          >
            
                <Input placeholder='State'/>
          </Form.Item>
          
          
          <Form.Item 
          {...tailLayout}
          >
          <Form.Item 
          {...tailLayout}
          >
            

          </Form.Item>
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditState