import {Button, Form, message, Space, Upload} from "antd";
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import React, { useState} from "react";
import { postAPI, putOuterAPI} from "../../../utils/apiRequest";
import {GET_FILE_UPLOAD_SIGNATURE} from "../../../constants/api";
import {_get} from "../../../utils/lodashUtils";
import ViewUploadedFile from "./ViewUploadedFile";
import {
    makeLocalFileURL
} from "../../../utils/common";
import {stringTruncate} from "../../../utils/fileUploadUtils";



let s3SignatureUrl, s3FileUploadType, s3SignatureFileName;


const UploadFile = (props) => {
    const [fileList, setFileList] = useState([])

    const handleFileUpload = info => {
        let fileList = [...info.fileList];
        props?.setLoading(true)
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            props.setLoading(false)
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
            props.setLoading(false)
        }
        let updatedFileList = fileList.map(file => {
            file.url = _get(file, "xhr.responseURL", "").split("?")[0];
            file.name = file.name.slice(0, 22) + (file.name.length > 22 ? "..." : "");
            return file;
        });
        setFileList(updatedFileList)
    };
    const customRequest = (customProps) => {
        uploadPresentURl(customProps,customProps?.file)
    }

    const fileUploadProps = {
        maxCount: 1,
        action: s3SignatureUrl,
        method: "put",
        onChange: handleFileUpload,
        customRequest:customRequest,
        headers: {
            "Content-Type": s3FileUploadType
        },

    };




    const uploadPresentURl=(customProps,file)=>{
        let successFn = function (data) {
            s3SignatureUrl = data.url;
            s3SignatureFileName = data.file_name;
            s3FileUploadType = file.type;
            uploadFileS3(customProps)
        };
        let errorFn = function () {
        };
        postAPI(
            GET_FILE_UPLOAD_SIGNATURE,
            {file: file.name, file_type: file.type},
            successFn,
            errorFn
        );
    }

    const uploadFileS3=(customProps)=>{
        let successFn = function (data) {
            customProps.onSuccess();
            if (props.isMultiple){
                props.setState((prevState) => ({
                    ...prevState,
                    [props.keyName]:prevState[props.keyName]?{...prevState[props.keyName],[props.name]: {
                            is_Upload: true,
                            file_name: s3SignatureFileName
                        }}:{[props.name]:{is_Upload:true,file_name:s3SignatureFileName}},

                }));
            }else {
                props.setState((prevState) => ({
                    ...prevState,
                    [props.name]: {is_Upload:true,file_name:s3SignatureFileName}
                }));
            }
        };
        let errorFn = function (error) {
        }
        putOuterAPI(
            s3SignatureUrl,
            customProps.file,
            successFn,
            errorFn,
            {
                "Content-Type": customProps.file.type
            },
        );
    }



    const viewUploadedFile = async (path) => {

        if (path) {
            window.open(makeLocalFileURL(path));
        }

        // let apiParams = {
        //     path
        // }
        // let msg = startLoadingMessage('Generating File ...');
        // let successFn = function (data) {
        //     if (data.url) {
        //         window.open(makeLocalFileURL(data.url));
        //     }
        //     stopLoadingMessage(
        //         msg,
        //         SUCCESS_MSG_TYPE,
        //         'File Generated Successfully!',
        //     );
        // }
        // let errorFn = function (error) {
        //     handleErrorResponse(error);
        //     stopLoadingMessage(msg, ERROR_MSG_TYPE, 'File Generation Failed!');
        // }
        // await getAPI(VIEW_UPLOADED_FILE, successFn, errorFn, apiParams)
    }

    const removeUploadFile=(key)=>{
        if (props.isMultiple){
            props.setState((prevState)=>{
                const temState = { ...prevState };
                delete temState[props.keyName][key]
                return temState;
            })
        }else {
            props.setState((prevState)=>{
                const temState = { ...prevState };
                temState[key] = undefined;
                return temState;
            })
            if(props.setBulkUploadErrors){
                props.setBulkUploadErrors([])
            }
        }
    }
    return (
        <Form.Item {...props} style={{margin:0, padding:0}}
        extra={props.extra?<Space>
            <a href={'#'} onClick={()=>viewUploadedFile(props.extra)}>
            {stringTruncate(props.extra, 18)}</a>
            <DeleteOutlined style={{color:'red'}} onClick={()=>removeUploadFile(props.name)} /></Space>:null}>
            <Upload {...fileUploadProps}
                    // customRequest={(props) => customRequest(props)}
                    className={"center my-upload"}
                    style={{display: "block", margin: "auto"}}
            >
                <Button>
                    <UploadOutlined/> Click to Upload
                </Button>

            </Upload>
            {props.initialValue ?
                <ViewUploadedFile
                    value={props?.initialValue}
                /> : null}
        </Form.Item>
    )
}
export default UploadFile