import React, {useState} from 'react'
import AppBase from '../../../base/AppBase'
import ViewPersonalLoan from './viewPersonalLoan';
import {Card, Tabs} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import AddOrEditCompany from "../company/AddOrEditCompany";
import ViewCompany from "../company/viewCompany";
import ViewExcelPersonalLoanApplicant from "./ViewExcelPersonalLoanApplicant";
import ExcelUploadStatus from "./ExcelUploadStatus";

const PersonalLoan = (props) => {
    const [selectedTab, setSelectedTab] = useState("website-leads")
    const [editData, setEditData] = useState(null);
    const onSelectTab = (option, extra) => {
        setSelectedTab(option)
        setEditData(extra);
    };
    return (
        <AppBase
            metaDetails={{
                title: 'Personal Loan',
            }}
        >
            <Card>
                <Tabs onChange={onSelectTab} activeKey={selectedTab}>
                    <TabPane tab="Website Leads" key="website-leads">
                        {selectedTab ==="website-leads"? (
                                <ViewPersonalLoan
                                    editData={editData}
                                    key={'website-leads'}
                                    onSelectTab={onSelectTab}
                                    {...props}/>
                            ):
                            null}
                    </TabPane>

                    <TabPane  tab='Excel Leads' key='excel-leads'>
                        {selectedTab === 'excel-leads' ? (
                            <ViewExcelPersonalLoanApplicant onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                    <TabPane  tab='Excel Upload Status' key='excel-upload-status'>
                        {selectedTab === 'excel-upload-status' ? (
                            <ExcelUploadStatus onSelectTab={onSelectTab} {...props} />
                        ) : null}
                    </TabPane>
                </Tabs>
            </Card>
        </AppBase>
    )
}


export default PersonalLoan
