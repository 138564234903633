import React, { useEffect, useState } from 'react';
import {Card, Col, Row, Tabs, Tag, Typography} from 'antd';
import moment from 'moment';



const {Text} = Typography


const ApplicantDetails = (props) => {
    const [location,setLocation] = useState({});
    useEffect(() => {
        setLocation(props.data);
    }, [props.data]);
    return (

            <React.Fragment >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="User Details" key="1">
                    <Row className={'view-heading'}>

                </Row>
                <Row justify={"start"}>,
                    <Col lg={{span: 24, offset: 1}} md={{span: 16, offset: 5}}>
                        <Row span={24} sty le={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Applicant Name'}/>{location?.applicant_name ? location?.applicant_name : "--"}
                            </Col>


                            <Col span={8}> <RenderEmployeeDetail
                                label={'Mobile'}/> {location?.mobileNo ? location?.mobileNo : "--"} {location?.is_mobileNo_verified ?
                                <Tag color={"success"}>Verified</Tag> : <Tag color={"warning"}>Not Verified</Tag>}</Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Pin Code'}/> {location?.pincode ? location?.pincode : "--"}
                            </Col>
                        </Row>

                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>

                            <Col span={8}> <RenderEmployeeDetail
                                label={'Email'}/> {location?.email ? location?.email : "--"}</Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'DOB'}/> {location?.dob ? moment(location?.dob).format("DD/MM/YYYY") : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'PAN NO.'}/> {location?.panNo ? location?.panNo : "--"}</Col>

                        </Row>



                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>

                            <Col span={8}> <RenderEmployeeDetail
                                label={'Employment Type'}/> {location?.employment_type ? location?.employment_type : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Company Name'}/> {location?.company_name ? location?.company_name : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Total Experience'}/> {location?.total_work_exp ? location?.total_work_exp : "--"}
                            </Col>


                        </Row>

                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                        <Col span={8}> <RenderEmployeeDetail
                                label={'Annual Income'}/> {location?.annual_income ? location?.annual_income : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Mother\'s Name'}/> {location?.mother_name ? location?.mother_name : "--"}
                            </Col>

                            <Col span={8}> <RenderEmployeeDetail
                                label={'Loan Amount'}/> {location?.desired_loan_amount ? location?.desired_loan_amount : "--"}
                            </Col>


                        </Row>

                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>

                        <Col span={8}> <RenderEmployeeDetail
                                label={'Loan Tenure'}/> {location?.tenure ? location?.tenure : "--"}</Col>


                        </Row>




                    </Col>

                </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tata" key="2">
                <Row className={'view-heading'}>

                </Row>
                <Row justify={"start"}>,
                    <Col lg={{span: 24, offset: 1}} md={{span: 16, offset: 5}}>
                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Approved Amount'}/> {location?.tata_approved_amount ? location?.tata_approved_amount : "--"}</Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Lead Id'}/>{location?.tata_lead_id ? location?.tata_lead_id : "--"}
                            </Col>
                            <Col> <RenderEmployeeDetail
                                label={'Tata Opportunity Id'}/> {location?.tata_opportunity_id ? location?.tata_opportunity_id : "--"}
                                </Col>
                        </Row>

                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Scheme Id'}/> {location?.tata_scheme_id ? location?.tata_scheme_id : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Source Txt Id'}/> {location?.tata_source_txn_id ? location?.tata_source_txn_id : "--"}</Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Status'}/> {location?.tata_status ? location?.tata_status : "--"}
                            </Col>

                        </Row>
                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata  Unique Ref'}/> {location?.tata_unique_ref ? location?.tata_unique_ref : "--"}</Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Webtop No'}/> {location?.tata_webtop_no ? location?.tata_webtop_no : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata ROI'}/> {location?.tata_roi ? location?.tata_roi : "--"}
                            </Col>
                        </Row>
                        <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Tata Tenure'}/> {location?.tata_tenure ? location?.tata_tenure : "--"}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="L&T" key="3">
                <Row span={24} style={{marginTop: '10px', color: 'GrayText'}}>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Application Id'}/> {location?.leadId ? location?.leadId : "--"}
                            </Col>
                            <Col span={8}> <RenderEmployeeDetail
                                label={'Status'}/> {location?.status ? location?.status : "--"}</Col>
                            <Col span={8} style={{paddingRight: 50}}> <RenderEmployeeDetail
                                label={'Reason'}/> {location?.errorDesc ? location?.errorDesc : "--"}
                            </Col>
                        </Row>
                </Tabs.TabPane>

            </Tabs>




            </React.Fragment>

    );
}

export default ApplicantDetails;

function RenderEmployeeDetail(props) {
    return (
        <Row
            style={{fontWeight: 'bold'}}
        >
            <Col>
                <Text>{props.label}</Text>
            </Col>
            <Col>
                <Text>{props.value}</Text>
            </Col>
        </Row>
    )
}
