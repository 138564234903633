import React from 'react';
import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  TreeSelect,
  Upload,
  message,
} from 'antd';
import { getAPI, postAPI, putAPI } from '../../../../utils/apiRequest/index';
import { UploadOutlined } from '@ant-design/icons';
import {
  FILE_UPLOAD,
  SETTINGS_DYNAMIC,
  SETTINGS_DYNAMIC_DETAIL,
  SETTINGS_DYNAMIC_DROPDOWN_LIST,
} from '../../../../constants/api';
import { _get } from '../../../../utils/lodashUtils';
import { interpolate, makeURL } from '../../../../utils/common';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import { HR_SEETINGS_DROPDOWN_SETTING_DESC } from '../../../../constants/description';
import AppBase from '../../../base/AppBase';


export default class DropdownSettings extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      dropdownList: [],
      loading: false,
      dropDownSettingsData: [],
      dropDownSettingsLoading: false,
      selectedPath: [],
      editData: null,
      selectedDisable: false
    };
  }

  componentDidMount() {
    this.loadDropDownList();
  }

  loadDropDownList = () => {
    let that = this;
    let successFn = function(result) {
      that.setState({
        dropdownList: result,
      });
    };
    let errorFn = function(error) {
    };
    getAPI(SETTINGS_DYNAMIC_DROPDOWN_LIST, successFn, errorFn);
  };
  loadDropDownSettings = (option) => {
    let that = this;
    let { selectedOption } = that.state;
    let apiParams = {
      name: _get(selectedOption, 'title'),
      pagination: false,
      parent: _get(selectedOption, 'parent'),
    };
    that.setState({
      dropDownSettingsLoading: true,
    });
    let successFn = function(result) {
      that.setState({
        dropDownSettingsData: result,
        dropDownSettingsLoading: false,
      });
    };
    let errorFn = function(error) {
      that.setState({
        dropDownSettingsLoading: false,
      });
    };
    getAPI(SETTINGS_DYNAMIC, successFn, errorFn, apiParams);
  };

  onChangeHandle = (values,node) => {
    let that = this;
    that.setState(
      {
        selectedOption: node,
        selectedPath: _get(node, 'path'),
        selectedDisable: _get(node, "can_disabled")
      },
      function() {
        that.loadDropDownSettings();
      },
    );
  };

  onFinish = (values) => {
    let that = this;
    let { selectedOption, editData } = that.state;
    let reqData = {
      ...values,
      name: _get(selectedOption, 'title'),
      parent: _get(selectedOption, 'parent'),
      icon:values?.icon?.file?.response?.image_path

    };
    that.setState({
      loading: true,
    });
    let successFn = function() {
      that.setState({
        loading: false,
        editData: null,
      });
      that.loadDropDownList();
      that.loadDropDownSettings();
      that.formRef.current.resetFields();
    };
    let errorFn = function() {
      that.setState({
        loading: false,
      });
    };
    if (editData) {
      putAPI(
        interpolate(SETTINGS_DYNAMIC_DETAIL, [editData.id]),
        reqData,
        successFn,
        errorFn,
      );
    } else {
      postAPI(SETTINGS_DYNAMIC, reqData, successFn, errorFn);
    }
  };
  editObject = (value) => {
    let that = this;
    that.setState({
      editData: value,
    });
  };

  disableObject = (value) => {
    let that = this;
    let reqData = {
      is_disabled: !value.is_disabled,
    };
    that.setState({
      dropDownSettingsLoading: true,
    });
    let successFn = function(result) {
      that.setState({
        dropDownSettingsLoading: false,
      });
      that.loadDropDownSettings();
    };
    let errorFn = function(error) {
      that.setState({
        dropDownSettingsLoading: false,
      });
    };
    putAPI(
      interpolate(SETTINGS_DYNAMIC_DETAIL, [value.id]),
      reqData,
      successFn,
      errorFn,
    );
  }

  deleteObject = (value) => {
    let that = this;
    let reqData = {
      is_active: false,
    };
    that.setState({
      dropDownSettingsLoading: true,
    });
    let successFn = function(result) {
      that.setState({
        dropDownSettingsLoading: false,
      });
      that.loadDropDownSettings();
    };
    let errorFn = function(error) {
      that.setState({
        dropDownSettingsLoading: false,
      });
    };
    putAPI(
      interpolate(SETTINGS_DYNAMIC_DETAIL, [value.id]),
      reqData,
      successFn,
      errorFn,
    );
  };

  handleCancel = () => {
    let that = this
    that.setState({
      editData: null,
    });
    that.setState({
      selectedOption: undefined,
    });
    that.formRef.current.resetFields();
  };

  render() {
    let that = this;
    const {
      dropdownList,
      dropDownSettingsLoading,
      dropDownSettingsData,
      selectedPath,
      selectedDisable
    } = that.state;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 4, span: 16 },
    };
    let authHeaders = {};
    const singleUploadprops = {
      name: 'image',
      data: {
        name: `hello`,
      },
      action: makeURL(FILE_UPLOAD),
      headers: {
        ...authHeaders,
      },
      onChange(info) {
        if(info){
          console.log(info,"hello");
        }
        if (info?.file?.status !== 'uploading') {
        }
        if (info?.file?.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info?.file?.status === 'error') {
          message.error(`${info?.file?.name} file upload failed.`);
        }
      },
      listType: 'picture',
      maxCount: 1,
    };

    const ViewIcon = (icon) => {
      window.open(`https://rpq.plutonic.co.in/media/${icon}`);
    }
    const handleFileRemove = () => {
      this.setState({
          bulkUploadErrors : []
        })
  }
    const columns = [
      {
        title: 'S. No.',
        key: 's_no',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => !!record.is_editable || !!record.is_deletable ? (
          <span>
           {!!record.is_editable ? (
             <a onClick={() => this.editObject(record)}>Edit</a>
           ) : <Tag>Not Editable</Tag>}
            <Divider type='vertical' />
            { selectedDisable? ( !record.is_disabled?  (<a onClick={() => this.disableObject(record)}>Disable</a>): 
             (<a onClick={() => this.disableObject(record)}>Enable</a>)) 
             : null }
            <Divider type='vertical'/>
            <a onClick={() => ViewIcon(record.icon)}> View </a>
            <Divider type='vertical'/>
            {!!record.is_deletable ? (
              <Popconfirm
                title='Are you sure to delete this?'
                onConfirm={() => this.deleteObject(record)}
                okText='Yes'
                cancelText='No'
              >
                <a >Delete</a>
              </Popconfirm>
            ) : <Tag>Not Deletable</Tag>}
          </span>
        ) : <Tag color='#108ee9'>Default</Tag>,
      },
    ];
    return (
      <AppBase metaDetails={{
        title: 'Dropdown Settings',
        subtitle: 'Dropdown Settings',
        description: HR_SEETINGS_DROPDOWN_SETTING_DESC,
      }}>

        <Card className={'mb-0'}>
          <Form
            {...layout}
            onFinish={this.onFinish}
            validateMessages={validateMessages}
            ref={this.formRef}
          >
            <Form.Item label={'Name'}>
              <TreeSelect
                showSearch
                onSelect={this.onChangeHandle}
                style={{ width: '100%' }}
                value={this.state.selectedOption}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={dropdownList}
                placeholder='Please select'
                node='leaf'
              />
              {/* <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'Please Select'}
                  optionFilterProp="children"
                  onSelect={(value) => this.onChangeHandle(value)}
                >
                  {dropdownList?.map((option) => (
                    <Select.Option label={option.title} key={option.id} value={option.}>
                      {option.title}
                    </Select.Option>
                  ))}
                </Select> */}
            </Form.Item>
            <Form.Item
              label={'Value'}
              name={'value'}
              rules={[{ required: true }]}
            >
              <Input placeholder={'Value'} />
            </Form.Item>
            <Form.Item
              label={'Icon'}
              name={'icon'}
            >
              <Upload {...singleUploadprops} onRemove={handleFileRemove}><Button icon={<UploadOutlined />}>Click To Upload</Button></Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  htmlType='submit'
                  type={'primary'}
                  className={'theme-color'}
                >
                  Save
                </Button>
                <Button onClick={that.handleCancel} >Cancel</Button>
              </Space>
            </Form.Item>
          </Form>

          <Divider />
          <h3>
            <Breadcrumb>
              {selectedPath.map((item) => (
                <Breadcrumb.Item>{item}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </h3>
          <Spin spinning={dropDownSettingsLoading}>
            <Table
              dataSource={dropDownSettingsData}
              columns={columns}
              pagination={false}
            />
          </Spin>
        </Card>
        <Modal
          key={this.state.editData && this.state.editData.id}
          title='Edit Dropdown Setting'
          visible={that.state.editData}
          onCancel={that.handleCancel}
          footer={null}
        >
          <Form
            initialValues={{ ...that.state.editData}}
            onFinish={this.onFinish}
            key={this.state.editData && this.state.editData.id}
          >
            <Form.Item label={'Value'} name={'value'}>
              <Input />
            </Form.Item>
            <Form.Item label={'Icon'} name={'icon'} extra={that.state?.editData?.icon ? 'Uploaded' : ''}>
            <Upload {...singleUploadprops}  onRemove={handleFileRemove} ><Button icon={<UploadOutlined />}>Click To Upload</Button></Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  htmlType='submit'
                  type={'primary'}
                  className={'theme-color'}
                >
                  Save
                </Button>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </AppBase>
    );
  }
}
const validateMessages = {
  required: REQUIRED_FIELD_MESSAGE,
};
