import React, { useRef, useState } from 'react';
import { Button, Form, Space, Spin, Tooltip,  Typography, Table } from "antd";
import { displayMessage } from "../../utils/common";
import { postAPI } from '../../utils/apiRequest';
import { SUCCESS_MSG_TYPE } from '../../constants/dataKeys';
import { handleBulkUploadErrors } from '../../utils/fileUploadUtils';
import { REQUIRED_FIELD_MESSAGE } from '../../constants/message';
import UploadFile from '../common/uploadFile';

const { Text } = Typography;


const BulkUploadFile = (props) => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const [bulkUploadErrors, setBulkUploadErrors] = useState([]);
    const [uploadFileState, setUploadFileState] = useState({});

    if(props.open){
        setUploadFileState({});
        setBulkUploadErrors([]);
    }

    const onFinish = (values) => {
        let reqData = {
            report_upload:
            uploadFileState.report_upload.file_name,
        };
        setLoading(true);
        let successFn = function (result) {
            setLoading(false);
            setBulkUploadErrors([]);
            displayMessage(SUCCESS_MSG_TYPE, props?.successMSG);
            props.setIsModalOpen(false)
            props.loadData();
            setUploadFileState({});
        };
        let errorFn = function (error) {
            setLoading(false);
            setBulkUploadErrors(error.data.length ? handleBulkUploadErrors(error.data) : [{id : 1}]);
        };
        postAPI(props.url, reqData, successFn, errorFn);
    };

    const handleFileRemove = () => {
        setBulkUploadErrors([]);
    }

    if(uploadFileState.report_upload === {} || undefined){
        setBulkUploadErrors([]);
    }

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };

    const handleCancel = () => {
        handleFileRemove();
        setUploadFileState({});
        setBulkUploadErrors([]);
        props.setIsModalOpen(false)
    }


    return (
        <Spin spinning={loading}>
            <Form
                onFinish={onFinish}
                validateMessages={validateMessages}
                ref={formRef}
                {...layout}
                key={'bulk_task_upload'}
            >
                <Form.Item label={'Upload Excel'}  rules={[{ required: true }]}>
                    <Space align={'baseline'}>
                        <UploadFile
                            key={'report_upload'}
                            name={"report_upload"}
                            colon={false}
                            setLoading={setLoading}
                            setState={setUploadFileState}
                            setBulkUploadErrors={setBulkUploadErrors}
                            uploadFileState={uploadFileState}
                            setUploadFileState={setUploadFileState}
                            extra={uploadFileState?.report_upload?.file_name}
                        />
                        <Tooltip title='Download Sample Format'>
                            <Typography.Link
                                target={'_blank'}
                                rel={'noreferrer'}
                                href={props.bulkFilePath}
                            >
                                Download Sample Format?
                            </Typography.Link>
                        </Tooltip>

                    </Space>
                </Form.Item>
                {bulkUploadErrors.length  ?
                    <Form.Item label={' '} colon={false}>
                        <Table columns={[
                            {
                                title: <Text type='danger'>Row</Text>,
                                key: 'row',
                                dataIndex: 'position',
                            },
                            {
                                title: <Text type='danger'>Error Msg</Text>,
                                key: 'error_msg',
                                dataIndex: 'error_msg',
                            },
                        ]} dataSource={bulkUploadErrors} pagination={false} />
                    </Form.Item> : null}
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button
                            htmlType='submit'
                            type={'primary'}
                            className={'theme-color'}
                        >
                            Save
                        </Button>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}
export default BulkUploadFile

const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
};