import { Button, Form, Input, Select, Space, Spin } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { COMPANY, SETTINGS_DYNAMIC, SETTINGS_DYNAMIC_DROPDOWN_LIST  } from '../../../../constants/api'
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys'
import { layout, tailLayout } from '../../../../constants/hardData'
import { getAPI, postAPI } from '../../../../utils/apiRequest'
import { displayMessage } from '../../../../utils/common'

const AddOrEditCompany = (props) => {

  const [loading, setLoading] = useState(false)
  const [DropDownList, setdropDownList] = useState([])

  const formRef = useRef()

  useEffect(() => {
    getDropDown().then(r => r);
  }, [])
  

  const getDropDown = async (value) => {
    
    let param = {
      name:'Company Type'
    }
    setLoading(true);
   
    let successFn = function(result){
        setLoading(false);
        setdropDownList(result?.results)
        console.log(result,"state");
    }
    let errorFn = function(error){
        setLoading(false)
        console.log(error)
    }
    await getAPI(SETTINGS_DYNAMIC, successFn, errorFn,param)
  }

 

  const onFinish = (values) => {
    let { editData } = props;
    setLoading(true)
    let reqData = {name:values?.name,company_type:values?.company_type
    }

    let successFn = function(){
      setLoading(false)
      props.onSelectTab('view-Company')
      if(editData){
        displayMessage(SUCCESS_MSG_TYPE, 'Company Updated Successfully.');

      }else{
      displayMessage(SUCCESS_MSG_TYPE, 'Company Added Successfully.');
      }
    }
    let errorFn = function(error){
      setLoading(false)
    }
    if (editData) {
      reqData.id = editData.id;
      postAPI(COMPANY, reqData, successFn, errorFn);
    }
    else{
    postAPI(COMPANY, reqData, successFn, errorFn)
    }
  }

  const handleCancelEdit = () => {
    props.onSelectTab("view-Company");
  }
   
  
  return (
   
        <Spin spinning={loading}>
          {console.log(props.editData,'edit')}
        <Form
          onFinish={onFinish}
          // validateMessages={validateMessages}
          {...layout}
          ref={formRef}
          key={props.editData ? props.editData.id : '1'}
          initialValues={{
            ...props.editData,
            state:props?.editData?.state,
            
          }}
        >
          
          <Form.Item
            label={'Company Type'}
            name={'company_type'}
            rules={[{ required: true }]}
          >
            <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={'Company Type'}
                  optionFilterProp="children"
                >
                  {DropDownList?.map((option) => (
                    <Select.Option label={option.name} key={option.id} value={option.id}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
          <Form.Item
            label={'Company'}
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Company'} />
          </Form.Item>
          
          <Form.Item 
          {...tailLayout}
          >
            <Space>
              <Button
                htmlType='submit'
                type={'primary'}
                className={'theme-color'}
              >
                Save
              </Button>
              {props.editData && 
            <Button onClick={handleCancelEdit} >Cancel</Button> }
            </Space>
          </Form.Item>
        </Form>
      </Spin>
  )
}

export default AddOrEditCompany