import React, { Component } from 'react';
import { Layout } from 'antd';
import AppSider from './sider/AppSider';
import AppHeader from './header/AppHeader';
import AppFooter from './footer/AppFooter';
import { _get } from '../../utils/lodashUtils';
import { loginUserAuthAction } from '../../redux/actions/authReduxActions';
import { connect } from 'react-redux';
import PageHeadingAndMetaDetails from './pageheader/PageHeader';


const { Content } = Layout;

class AppBase extends Component {
  render() {
    const { noSider, noHeader, noPadding, noBreadcrumb, metaDetails = {} } = this.props;
    return (
      <Layout>
        {noHeader ? null : <AppHeader noBreadcrumb={noBreadcrumb} />}
        <Layout
          className='site-layout'
          style={{
            marginTop: 64,
          }}
        >

          {noSider ? null : null}
          <Content
            style={{
              padding: noPadding ? 0 : 10,
              height: `calc(100vh - 65px)`,
              overflow: 'auto',
            }}
          >
            <PageHeadingAndMetaDetails  {...metaDetails} />
            {this.props.children}
            <AppFooter />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, 'auth.user'),
  };
};
const mapDispatchToProps = {
  loginUserAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBase);
