import React, {Component} from 'react';
import {Avatar, Layout, List, Menu} from 'antd';
import {connect} from 'react-redux';
import {_get} from '../../../utils/lodashUtils';
import {globalSiderCollapsed, globalSiderMenu} from '../../../redux/actions/globalReducActions';
import {Link, withRouter} from 'react-router-dom';
import {
    FormOutlined,
    SettingOutlined,
    UserOutlined,
    ExclamationCircleOutlined,HeatMapOutlined ,MoneyCollectOutlined
} from '@ant-design/icons';
import {SUCCESS_COLOR,} from '../../../constants/hardData';
import {makeFileURL} from '../../../utils/common';
import { getAPI } from '../../../utils/apiRequest';
import { USER_CLONE } from '../../../constants/api';
import { userNameStr1 } from '../header/AppHeader';

const {Sider} = Layout;



class AppSider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:""
        };
    } 
   

    onSwitchPanel = (item) => {
        let that = this;
        that.props.globalSiderMenu([item]);
    };
    
   

    render() {
        let that = this;
        const {
            siderCollapsed,
            route,
            location,
            userDetail,
            userImage,
        } = that.props;
        return (
            
            <Sider
                trigger={null}
                collapsible
                collapsed={siderCollapsed}
                className={'theme-background'}
                width={225}
            >
                <div className='logo' style={{padding: 20, paddingBottom: 0}}>
                    {siderCollapsed ? (
                        <Avatar
                            src={makeFileURL(userImage)}
                            alt={_get(userDetail, 'first_name')}
                            size={40}
                            icon={<UserOutlined/>}
                            style={{
                                margin: 'auto',
                                display: 'block',
                                background: SUCCESS_COLOR,
                            }}
                        />
                    ) : (
                        <List.Item.Meta 
                            avatar={
                                <Avatar
                                    src={makeFileURL(userImage)}
                                    size={50}
                                    icon={<UserOutlined/>}
                                    style={{background: SUCCESS_COLOR}}
                                />
                            }
                            // title={_get(userDetail, 'first_name')}
                            title={userNameStr1}
                            
                            description={_get(userDetail, 'employee_code_data')}
                        />
                    )}
                </div>
                <Menu
                    defaultSelectedKeys={[`${location.pathname}`]}
                    key={`${_get(route, "cat")}-${_get(route, "subCat")}`}
                    defaultOpenKeys={[`${_get(route, "cat")}-${_get(route, "subCat")}`]}
                    style={{
                        height: 'calc(100vh - 140px)',
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                    }}
                    mode='inline'
                >
                    <Menu.SubMenu
                        key="admin-settings"
                        title="Settings"
                        icon={<SettingOutlined/>}>
                        <Menu.Item key={'/admin/setting/drop-down'}>
                            <Link to={'/admin/setting/drop-down'}>Dropdown Settings</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/company'}>
                            <Link to={'/admin/company'}>Company</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                     
                    <Menu.SubMenu
                        title="Address Settings"
                        icon={<FormOutlined/>}>
                        <Menu.Item key={'/admin/setting/address-setting/state'}>
                            <Link to={'/admin/setting/address-setting/state'}>State</Link>
                        </Menu.Item>
                        <Menu.Item key={'/admin/setting/address-setting/city'}>
                            <Link to={'/admin/setting/address-setting/city'}>City</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    
                    <Menu.Item icon={<MoneyCollectOutlined />} key={'/admin/personalLoan'}>
                        <Link to={'/admin/personalLoan'}>Personal Loan</Link>
                    </Menu.Item>
                    
                </Menu>
            </Sider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        route: _get(state, 'global.route'),
        currentSiderMenu: _get(state, 'global.currentSiderMenu'),
        userRoles: _get(state, 'auth.userRoles'),
        userDetail: _get(state, 'auth.user'),
        userImage: _get(state, 'auth.userImage'),
        siderCollapsed: _get(state, 'global.siderCollapsed'),
    };
};
const mapDispatchToProps = {
    globalSiderCollapsed,
    globalSiderMenu,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AppSider));


