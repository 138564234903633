import { makeFileURL } from './common';

export const getFileListFromFileUploadProps = function(props) {
  if (!props) {
    return [];
  }
  if (props.file) {
    return props.fileList || [];
  }
  if (Array.isArray(props)) {
    return props;
  }
};

export const convertFileUrlToUploadFileList = function(props) {
  if (!props || (Array.isArray(props) && !props.length)) {
    return [];
  } else if (Array.isArray(props)) {
    return props.map((item) => {
      return {
        uid: '-1',
        name: 'Uploaded File',
        status: 'done',
        url: makeFileURL(item),
        image_path: item,
        image: makeFileURL(item),
        response: {
          image_path: item,
        },
      };
    });
  } else {
    return [
      {
        uid: '-1',
        name: 'Uploaded File',
        status: 'done',
        url: makeFileURL(props),
        image_path: props,
        image: makeFileURL(props),
        response: {
          image_path: props,
        },
      },
    ];
  }
};

export const getFilePathFromFileUploadFormValue = function(props = {}) {
  if (props?.file && props?.file.response) return props.file.response.image_path;
  else if (
    props?.fileList &&
    props?.fileList.length &&
    props?.fileList[0].response
  ) {
    return props.fileList[0].response.image_path;
  } else if (Array.isArray(props) && props.length && props[0].response) {
    return props[0].response.image_path;
  }
  return null;
};

export const getUploadedFileName = (data, isMultiple, keyName) => {
  if (isMultiple) {
    if (data) {
      let temp_name
      data.forEach((item) => {
        temp_name = item[keyName]?.file_name
      })
      return temp_name
    }

  } else {
    if (data) {
      return data?.file_name
    }
  }

}

// export  const getFileName=()=>{
//   let {uploadedState,keyName,name} = props
//   console.log("============>",uploadedState,keyName )
//   if (uploadedState){
//     if (props.isMultiple && uploadedState[keyName]) {
//       let file_path
//       uploadedState[keyName]?.map((item) => {
//         file_path = item[name]?.file_name
//       })
//       return file_path
//     } else {
//       return uploadedState[props.name]?.file_name
//     }
//   }else {
//     return null
//   }
// }

export const handleBulkUploadErrors = (errors) => {
  if (Array.isArray(errors)) {
    let errorList = [];
    errors.forEach(function(error, index) {
      if (error.detail) {
        if (Array.isArray(error.detail)) {
          error?.detail?.forEach(function(item) {
            errorList.push({
              position: index + 1,
              error_msg: item,
            });
          });
        } else {
          errorList.push({
            position: index + 1,
            error_msg: error.detail,
          });
        }
      }
    });
    return errorList;
  }else {
    return []
  }
};

export const stringTruncate = (string, length, end = "...") => {
  return string?.length < length ? string : string?.substring(0, length) + end;
};
