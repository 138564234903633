import React, {useEffect, useState} from "react";
import {Button, Card, Modal, Space, Spin, Table, Tag, Typography} from "antd";
import moment from "moment/moment";
import {getAPI} from "../../../../utils/apiRequest";
import {BULK_EXCEL_UPLOAD_STATUS, BULK_UPLOAD,} from "../../../../constants/api";
import {FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import CustomPagination from "../../../common/customizePagination";
import BulkUploadFile from "../../../common/BulkUploadFile";
import ViewUploadedFile from "../../../common/uploadFile/ViewUploadedFile";

const ExcelUploadStatus = (props) => {

    const [loading, setLoading] = useState(false)
    const [PersonalLoanList, setPersonalLoanList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [startDate, setStartDate] = useState(moment().subtract(15, "days"));
    const [endDate, setEndDate] = useState(moment());
    const [count, setCount] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filters, setFilters] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalOpen, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState([]);

    useEffect(() => {
        getPersonalLoan();
    }, [startDate, endDate, current, filters]);


    const getPersonalLoan = (page = 1) => {
        setLoading(true)
        let apiParams = {
            page: current,
            ...filters
        }
        let successFn = function (result) {
            setLoading(false)
            setPersonalLoanList(result.results)
            setNextPage(result.next)
            setCount(result.count);
            setPrevPage(result.previous);
            setCurrent(result.current);
            setTotalPage(result.results.length);
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(BULK_EXCEL_UPLOAD_STATUS, successFn, errorFn, apiParams)
    }

    useEffect(() => {
        setFilters((prevState) => ({
            ...prevState,
            [searchedColumn]: searchText ? searchText : null,
        }));
    }, [searchText, searchedColumn]);


    const handleNext = () => {
        setCurrent(current + 1);
    };

    const handlePrevious = () => {
        setCurrent(current - 1);
    };

    const handleJump = (page) => {
        setCurrent(page);
    };


    const HandleModal = (records) => {
        setModalData(records);
        setOpenModal(true);
    }


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                (20 * (current - 1)) + PersonalLoanList.indexOf(record) + 1,
        },
        {
            title: 'Date',
            key: 'created_date',
            render: (item, record) => (
                <span>{record?.created_date ? moment(record.created_date).format('DD/MM/YYYY HH:MM:SS A') : "--"}</span>
            ),
        },
        {
            title: 'Total Count',
            key: 'total_count',
            render: (item, record) => (
                <span>{record.total_count ? record.total_count : "--"}</span>
            ),
        }, {
            title: 'Complete Count',
            key: 'completed_count',
            render: (item, record) => (
                <span>{record.completed_count ? record.completed_count : "--"}</span>
            ),
        }, {
            title: 'Upload Status',
            key: 'is_processing',
            render: (item, record) => (
                <span style={{textAlign: "center"}}>
                    {record.is_completed ? <Tag color="green">Completed</Tag> : record.is_processing ?
                        <Tag color="#F6BE00">Processing</Tag> :
                        <Tag color="yellow">Pending</Tag>
                    }
        </span>
            ),
        },

        {
            title: 'Uploaded File',
            key: 'report_upload',
            render: (item, record) => (
                <span>{record?.report_upload ? <ViewUploadedFile value={record?.report_upload}/> : "--"}</span>
            ),
        },


        {
            title: 'Errors',
            key: 'error',
            render: (item, record) => (


                <Button type={"primary"} style={{borderRadius: 8}} onClick={() => HandleModal(record)}>Details</Button>

            ),
        },
    ]
    return (

        <Card
            headStyle={{padding: 8}}
            bodyStyle={{padding: 8}}
            // extra={
            //     <Space>
            //         <Button size={'medium'} disabled={false}
            //                 onClick={() => setIsModalOpen(true)}
            //         >
            //             <PlusOutlined/>Upload from Excel
            //         </Button>
            //         <Button style={{color: "green", borderColor: "green"}} icon={<FileExcelOutlined/>}
            //                 onClick={() => export_csv()}>Excel</Button>
            //     </Space>
            // }
        >
            <Spin spinning={loading}>
                <Table
                    dataSource={PersonalLoanList}
                    columns={columns}
                    pagination={false}
                />
            </Spin>

            <div style={{textAlign: 'center'}}>
                <CustomPagination
                    count={count}
                    next={nextPage}
                    previous={prevPage}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    current={current}
                    totalPage={totalPage}
                    onJump={handleJump}
                    pageLimit={20}
                />
            </div>
            <Modal title={'Bulk Upload Personal Loan'}
                   open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} width={700} destroyOnClose>
                <BulkUploadFile
                    setIsModalOpen={setIsModalOpen}
                    loadData={getPersonalLoan}
                    url={BULK_UPLOAD}
                    bulkFilePath={'/rpq-bulk-applicant.xlsx'}
                    successMSG={'Bulk Personal Loan Successfully Created'}
                />
            </Modal>
            <Modal width={1200} open={modalOpen} title={<Typography>Error's</Typography>}
                   footer={false} onCancel={() => setOpenModal(false)} destroyOnClose>
                <Table dataSource={modalData.errors || []} columns={[{
                    title: 'S.no',
                    key: 's_no',
                    render: (item, record, index) => index + 1,

                }, {
                    title: 'Row',
                    key: 'row',
                    render: (item, record) => <span>{record.row ? record.row : "--"}</span>
                }, {
                    title: 'Reason',
                    key: 'reason',
                    render: (item, record) => <span>{record.reason ? record.reason : "--"}</span>
                }]} pagination={false}/>
            </Modal>

        </Card>

    )
}

export default ExcelUploadStatus
