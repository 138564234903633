import { Button, Input, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';

const CustomPagination = ({ count, next, previous, onNext, onPrevious, current, totalPage, onJump, pageLimit }) => {

  const [newCurrent, setNewCurrent] = useState(current)
  const inputRef = useRef()
  if (pageLimit === 0 && count === 0) {
    pageLimit = 1;
    count = 1;
  }

  const sizeOfPage = parseInt(Math.ceil(count / pageLimit));

  const handleJump = (e) => {
    const page = parseInt(e.target.value);
    if (page > sizeOfPage) {
      onJump(sizeOfPage);
    } else if (page < 1) {
      onJump(1);
    } else {
      onJump(page);
    }
  };

  useEffect(() => {
    setNewCurrent(current)
    inputRef.current.input.value = current

  }, [current])

  const handleInputChange = (e) => {
   setNewCurrent(e.target.value)
   console.log(e.target.value)
  }

  const handleBlur = () => {
    const value = parseInt(newCurrent)
    if(value > sizeOfPage){
      setNewCurrent(sizeOfPage)
    }
  }
  

  return (
    <div className='flex-center'>
      <Button disabled={!previous} style={{ border: 'none', margin: '12px' }} onClick={onPrevious}>
        <LeftOutlined />
      </Button>
      <Typography.Text>
        <Input
          type='number'
          min={1}
          ref={inputRef}
          max={sizeOfPage}
          onBlur={handleBlur}
          value={newCurrent}
          onChange={handleInputChange}
          onPressEnter={handleJump}
          style={{ width: '65px', textAlign: 'center' }}
        />
        of {sizeOfPage}
      </Typography.Text>
      <Button disabled={!next} style={{ border: 'none', margin: '12px' }} onClick={onNext}>
        <RightOutlined />
      </Button>
    </div>
  );
};

export default CustomPagination;
