import React, { Component } from 'react';
import { Avatar, Col, Dropdown, Layout, Menu, Row, Space, Typography } from 'antd';
import {
  LogoutOutlined,
  TeamOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import { globalSiderCollapsed } from '../../../redux/actions/globalReducActions';
import { logoutUserAuthAction } from '../../../redux/actions/authReduxActions';
import { connect } from 'react-redux';
import { USER_CLONE, USER_LOGOUT } from '../../../constants/api';
import { getAPI, postAPI } from '../../../utils/apiRequest';
import { Link } from 'react-router-dom';
import { makeFileURL } from '../../../utils/common';
import { withRouter } from 'react-router';
import { AUTH_TOKEN } from '../../../constants/dataKeys';
import Logo from '../../../logo.png'
import Lockr from 'lockr';

const { Header } = Layout;
export let userNameStr1 = "";

class AppHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userNameStr:''
    };
  }
  

    cloneUser = () => {
      let that = this;
    let successFn = function(data) {
      console.log(data,"helll");
      that.setState({
        userNameStr:data?.username
      });
      userNameStr1=data.username
      
    };
    let errorFn = function() {
    };
    getAPI(USER_CLONE, successFn, errorFn);
  };

  componentDidMount(){
    this.cloneUser();
  }

  logOutUser = () => {
    let that = this;
    let successFn = function() {
      that.props.logoutUserAuthAction();
    };
    let errorFn = function() {
    };
    postAPI(USER_LOGOUT, {token: Lockr.get(AUTH_TOKEN)}, successFn, errorFn);
  };
  onHandleLink = (event) => {
    
    let { history } = this.props;
    if (event.key === 'logout') {
      this.logOutUser();
    } else {
      history.push(event.key);
    }
  };
  componentWillReceiveProps = async (nextProps, nextContext) => {

    console.log(nextProps, "props", this.props);
  }

  render() {
    let that = this;
    const { siderCollapsed, userImage, noBreadcrumb } =
      that.props;
      
    let { history } = this.props;
    let userMenu = (
      <Menu onClick={this.onHandleLink}>
        {/* <Menu.Item icon={<UserOutlined />} key={'/profile'}>
          <Link to={'/profile'}>Profile</Link>
        </Menu.Item> */}
        <Menu.Item icon={<UnlockOutlined />} key={'/change-password'}>
          <Link to={`/change-password`}>Change Password</Link>
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key={'logout'}>
          Log Out
        </Menu.Item>
      </Menu>
    );

    let settingsMenu = (
      <Menu>
        <Menu.Item icon={<SettingOutlined />} key={'/admin/setting/drop-down'}>
          <Link to={`/admin/setting/drop-down`}>Dropdown Settings</Link>
        </Menu.Item>
        <Menu.Item icon={<TeamOutlined/>} key={'/admin/company'}>
        <Link to={`/admin/company`}>Company</Link>
        </Menu.Item>
      </Menu>
    );

    let addressSettings = (
      <Menu>
        <Menu.Item icon={<EnvironmentOutlined />} key={'/admin/setting/address-setting/state'}>
          <Link to={`/admin/setting/address-setting/state`}>State</Link>
        </Menu.Item>
        <Menu.Item icon={<EnvironmentOutlined />} key={'/admin/setting/address-setting/city'}>
        <Link to={`/admin/setting/address-setting/city`}>City</Link>
        </Menu.Item>
      </Menu>
    );

    return (
      
      <Header
        className='site-layout-background'
        style={{
          background: '#fff',
          boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
          padding: '0 10px',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Row align={'center'}>
          <Col flex={siderCollapsed ? '110px' : '235px'}>
            <div>
                {!siderCollapsed?<span onClick={() => history.push('/')}>
                  <img
                      src={Logo}
                      alt='RupeeQ'
                      style={{ maxWidth: '100%', maxHeight: 55, marginRight: 18 }}
                  />

                </span>:
                    <img
                        src={Logo}
                        alt='RupeeQ'
                        style={{ maxWidth: '60%', maxHeight: 55, marginRight: 18 }}
                    />
                }




              {/* {!siderCollapsed ? (
                      <MenuFoldOutlined
                          onClick={() => this.props.globalSiderCollapsed(true)}
                          style={{ fontSize: 18 }}
                      />

              ) : (
                <MenuUnfoldOutlined
                  onClick={() => this.props.globalSiderCollapsed(false)}
                  style={{ fontSize: 18 }}
                />
              )} */}
            </div>
          </Col>
          <Col flex='auto'>
            
            <Menu theme="light" mode="horizontal">
          <Menu.Item key="/view-requisition">
            <Dropdown overlay={settingsMenu}>
              <Typography>Settings</Typography>
            </Dropdown>
            
          </Menu.Item>
          <Menu.Item key="/add-quotation">
          <Dropdown overlay={addressSettings}>
              <Typography>Address Settings</Typography>
            </Dropdown>
          </Menu.Item>
          <Menu.Item key="/admin/personalLoan">
            <Link to="/admin/personalLoan">Personal Loan</Link>
          </Menu.Item>
        </Menu>
              
            </Col>
          <Col span={8}>
            <div style={{ float: 'right', padding: '0 10px' }}>
              <Space align='baseline'>
                <Dropdown overlay={userMenu}>
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ backgroundColor: '#87d068' }}
                    src={makeFileURL(userImage)}
                  />
                </Dropdown>
                <span style={{fontWeight:'bold'}}>{userNameStr1}</span>
                {console.log(userNameStr1,'hell')}
              </Space>
            </div>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state,"kk");
  return {
    
    route: _get(state, 'global.route') || {},
    siderCollapsed: _get(state, 'global.siderCollapsed'),
    loginUser: _get(state, 'auth.user'),
    userImage: _get(state, 'auth.userImage'),
    userRoles: _get(state, 'auth.userRoles', []),
  };
};
const mapDispatchToProps = {
  globalSiderCollapsed,
  logoutUserAuthAction,
};
// export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppHeader));
