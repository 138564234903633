import { Card, Divider, Popconfirm, Spin, Table, Space, Select, Form } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { STATE } from '../../../../../constants/api'
import { getAPI, postAPI } from '../../../../../utils/apiRequest'
import InfiniteFeedLoaderButton from '../../../../common/InfiniteFeedLoaderButton'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const ViewState = (props) => {
    const [loading, setLoading] = useState(false)
    const [stateList, setStateList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [filterKeys, setFilterKeys] = useState({})
    const [stateFilterList, setStateFilterList] = useState([]);

    const formRef = useRef()


    useEffect(() => {
      getState();
      getFilterState();
    }, [filterKeys])

    

    const getState = (page=1) => {
        setLoading(true)
        
        let apiParams = {
          page,
          ...filterKeys,
        }
        let successFn = function(result){
            setLoading(false)
            setStateList([...(result.current === 1? [] : stateList), ...result.results])
            setNextPage(result.next)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(STATE, successFn, errorFn, apiParams)
    }

    const getFilterState = () => {
        setLoading(true)
        
        let apiParams = {
        
          pagination : false,
        }
        let successFn = function(result){
           setStateFilterList(result)
        }

        let errorFn = function(error){
            setLoading(false)
            console.log(error)
        }
       getAPI(STATE, successFn, errorFn, apiParams)
    }

   

    
    const deleteObject = (record) => {
      let reqData = {
        ...record,
        is_active: false,
      };
      let successFn = function (result) {
        getState();
      };
      let errorFn = function (error) {};
  
     postAPI(STATE, reqData, successFn, errorFn)
    };

    const editObject = (record) => {
      props.onSelectTab("add-state", record);
    };

    

    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
            stateList.indexOf(record) + 1,
        },
        {
            title: 'State',
            key: 'name',
            render: (item, record) => (
                <span>
                  {record.name
                    ? `${record.name}`
                    : '--'}
                </span>
              ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <span>
              <a onClick={() => editObject(record)}>< EditOutlined style={{ color: 'blue' }}/></a>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deleteObject(record)}
                okText="Yes"
                cancelText="No"
              >
                <a><DeleteOutlined style={{ color: 'red' }}/></a>
              </Popconfirm>
            </span>
          ),
        },
    ]

    const filterAddress=(type, value)=>{
      setFilterKeys(filterKeys => {
        return {...filterKeys, [type]: value ? value : undefined}
    })
      
  }
    
  return (
    <Card
    title={
      <Space>
          Filters :
          <Form layout={'inline'} ref={formRef}>
              
              <Form.Item name={'state'}>
                  <Select
                      placeholder={'State'}
                      allowClear
                      style={{width: "150px"}}
                      onChange={(e) => filterAddress('id', e)}
                      showSearch
                      optionFilterProp="children"
                  >
                      {stateFilterList.map((option) => {
                          return (
                              <Select.Option label={option.name} value={option.id}>
                                  {option.name}
                              </Select.Option>
                          )
                      })}
                  </Select>
              </Form.Item>
              </Form>
              </Space> }>
    <Spin spinning={loading}>
          <Table
            dataSource={stateList}
            columns={columns}
            pagination={false}
          />
        </Spin>
        <InfiniteFeedLoaderButton
          loaderFunction={() => getState(nextPage)}
          loading={loading}
          hidden={!nextPage}
        />
      </Card>
  )
}

export default ViewState