import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { loginUserAuthAction } from '../../../../redux/actions/authReduxActions';
import { globalSiderMenu } from '../../../../redux/actions/globalReducActions';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import AuthBase from '../AuthBase';
import { postAPI, postWithOutTokenAPI } from '../../../../utils/apiRequest';
import { RESET_PASSWORD_MAIL, USER_LOGIN } from '../../../../constants/api';
import { displayMessage } from '../../../../utils/common';
import { ERROR_MSG_TYPE, SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';

class LogIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: null,
      resetModalVisible: false,
    };
  }

  onFinish = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let successFn = function(result) {
      console.log(result,"ejhbwe");
      that.props.loginUserAuthAction(result);
      that.props.globalSiderMenu(_get(result, 'user.roles_data', []));
      that.props.history.push('/');
      displayMessage(SUCCESS_MSG_TYPE, 'User Loggedin Successfully.');
      that.setState({
        loading: false,
      });
    };
    let errorFn = function(error) {
      that.setState({
        loading: false,
      });
      Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, item));
    };
    postWithOutTokenAPI(USER_LOGIN, values, successFn, errorFn);
  };

  showResetModal = () => {
    let { resetModalVisible } = this.state;
    this.setState({
      resetModalVisible: !resetModalVisible,
    });
  };
  changeUserMail = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  handleOk = (e) => {
    const that = this;
    let { username } = that.state;
    if (!username) {
      displayMessage(ERROR_MSG_TYPE, 'Please fill Email Field');
      return true;
    }
    const successFn = function(data) {
      displayMessage(
        'success',
        'Link to update your password have been sent to your mail.',
      );
      that.setState({
        resetModalVisible: false,
        username: null,
      });
    };
    const errorFn = function(error) {
      Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, item));
    };
    postAPI(RESET_PASSWORD_MAIL, { username: username }, successFn, errorFn);
  };

  render() {
    let { loading, resetModalVisible } = this.state;
    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Login to your Account</h2>
            <Spin spinning={loading}>
              <Form
                id={'components-form-demo-normal-login'}
                name='normal_login'
                size={'large'}
                className='login-form'
                validateMessages={validateMessages}
                // initialValues={{username: 'admin@admin.com', password: "Password@###"}}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name='username'
                  rules={[
                    { required: true, message: 'Please input your username!' },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className={'input-icon-color'} />}
                    placeholder='Username'
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    { required: true, message: 'Please input your Password!' },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className={'input-icon-color'} />}
                    placeholder='Password'
                  />
                </Form.Item>
                <Form.Item>
                  <div className={'btnFloatRight'}>
                    <Button
                      size={'small'}
                      type={'link'}
                      className='login-form-forgot'
                      onClick={this.showResetModal}
                    >
                      Forgot password
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    type={'primary'}
                    size={'large'}
                    htmlType='submit'
                    className='login-form-button theme-color'
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
            <Modal
              title='Email to Reset Password'
              visible={resetModalVisible}
              onOk={this.handleOk}
              onCancel={this.showResetModal}
              okText='Reset Password'
            >
              <Input
                placeholder='Username'
                prefix={<MailOutlined />}
                onChange={this.changeUserMail}
              />
            </Modal>
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

const validateMessages = {
  required: REQUIRED_FIELD_MESSAGE,
};
const mapStateToProps = (state) => {
  return {
    loggedIn: _get(state, 'auth.loggedIn'),
  };
};

const mapDispatchToProps = {
  loginUserAuthAction,
  globalSiderMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
