import React, {useEffect, useState} from "react";
import {Button, Card, DatePicker, Input, Modal, Select, Space, Spin, Table, Tag, Typography} from "antd";
import moment from "moment/moment";
import {getAPI, postAPI} from "../../../../utils/apiRequest";
import {
     BULK_EXCEL_UPLOAD_STATUS,
    BULK_UPLOAD,
    ExcelPersonalLoan,
    LNT_SUBMIT,
    PersonalLoan,
    TATA_SUBMIT
} from "../../../../constants/api";
import {FileExcelOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import CustomPagination from "../../../common/customizePagination";
import BulkUploadFile from "../../../common/BulkUploadFile";
import ApplicantDetails from "./ApplicantDetails";
import {displayMessage, makeFileURL, truncateMobileNumber} from "../../../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../../../constants/dataKeys";
import {AMOUNT_RANGES} from "../../../../constants/hardData";

const {RangePicker} = DatePicker;
const ViewExcelPersonalLoanApplicant = (props) => {

    const [loading, setLoading] = useState(false)
    const [PersonalLoanList, setPersonalLoanList] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [startDate, setStartDate] = useState(moment().subtract(15, "days"));
    const [endDate, setEndDate] = useState(moment());
    const [count, setCount] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filters, setFilters] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalOpen,setOpenModal] = useState(false);
    const [modalData,setModalData] = useState([]);

    useEffect(() => {
        getPersonalLoan();
    }, [startDate, endDate, current, filters]);


    const getPersonalLoan = (page = 1) => {
        setLoading(true)
        let apiParams = {
            start: startDate && moment(startDate).format("YYYY-MM-DD"),
            end: endDate && moment(endDate).format("YYYY-MM-DD"),
            page: current,
            ...filters
        }
        let successFn = function (result) {
            setLoading(false)
            setPersonalLoanList(result.results)
            setNextPage(result.next)
            setCount(result.count);
            setPrevPage(result.previous);
            setCurrent(result.current);
            setTotalPage(result.results.length);
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(ExcelPersonalLoan, successFn, errorFn, apiParams)
    }

    useEffect(() => {
        setFilters((prevState) => ({
            ...prevState,
            [searchedColumn]: searchText ? searchText : null,
        }));
    }, [searchText, searchedColumn]);


    const handleNext = () => {
        setCurrent(current + 1);
    };

    const handlePrevious = () => {
        setCurrent(current - 1);
    };

    const handleJump = (page) => {
        setCurrent(page);
    };




    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText(null);
    };

    const getColumnSearchProps = (dataIndex, searchType = "String") => ({
        filterDropdown: ({
                             setSelectedKeys,
                             selectedKeys,
                             confirm,
                             clearFilters,
                             close,
                         }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {searchType === "String" ? (
                    <Input
                        placeholder={`Search...`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                ) : null}

                {searchType === "DropDown" ? (
                    <Select
                        showSearch
                        allowClear
                        style={{
                            marginBottom: 8,
                            display: "block",
                        }}
                        placeholder={'Select Amount'}
                        optionFilterProp='children'
                        onChange={(e) =>
                            setSelectedKeys(e ? [e] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        {AMOUNT_RANGES.map((option) => (
                            <Select.Option label={option.value} value={option.value}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                ) : null}

                <Space>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Ok
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
    });

    const export_csv = () => {
        // setLoading(true)
        let apiParams = {
            start: startDate && moment(startDate).format("YYYY-MM-DD"),
            end: endDate && moment(endDate).format("YYYY-MM-DD"),
            export_csv: true
        };
        let successFn = function (result) {
            openimg(result.report_upload);
        }
        let errorFn = function (error) {

            console.log(error)
        }
        getAPI(ExcelPersonalLoan, successFn, errorFn, apiParams)
    }
    const onChangeDateRange = (dates, dateStrings) => {
        const ho = () => {
            setStartDate(dates && dates[0]);
            setEndDate(dates && dates[1]);
            setCurrent(1);
        }
        ho();
    };

    const openimg = (link) => {
        window.open(makeFileURL(link));
    }

    const HandleModal = (records) => {
        setModalData(records);
        setOpenModal(true);
    }
    const tataChangeStatus =(records)=>{
        setLoading(true)
        let apiParams = {
            id: records?.id,
            is_excel:true
        };
        let successFn = function (result) {
            displayMessage(SUCCESS_MSG_TYPE,'Status Has Been Changed')
            getPersonalLoan()
            setLoading(false)
        }
        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        postAPI(TATA_SUBMIT, apiParams, successFn, errorFn)
    }
    const lntChangeStatus =(records)=>{
        setLoading(true)
        let apiParams = {
            id: records?.id,
            is_excel:true
        };
        let successFn = function (result) {
            displayMessage(SUCCESS_MSG_TYPE,'Status Has Been Changed')
            getPersonalLoan()
            setLoading(false)
        }
        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        postAPI(LNT_SUBMIT, apiParams, successFn, errorFn)
    }

    const excelUploadStatus =(records)=>{
        setLoading(true)
        let apiParams = {
            id: records?.id,
        };
        let successFn = function (result) {
            console.log("-=======", result)
            setLoading(false)
        }
        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(BULK_EXCEL_UPLOAD_STATUS, successFn, errorFn,apiParams)
    }


    const columns = [
        {
            title: 'S. No.',
            key: 's_no',
            render: (item, record, index) =>
                (20 * (current - 1)) + PersonalLoanList.indexOf(record) + 1,
        },
        {
            title: 'Query Details',
            key: 'query_details',
            render: (item, record) => (
                <span>{record?.created_date ? <span><Space>{record?.id}</Space><br/><Space>{moment(record?.created_date).format('DD/MM/YYYY')}</Space><br/><Space>{moment(record?.created_date)?.format('HH:mm A')}</Space></span> : '--'}</span>
            ),
        },
        {
            title: 'Name & PIN Code',
            key: 'applicant_name',
            render: (item, record) => (
                <div style={{width: 150}}>
                    <span><Space>{record?.applicant_name ? record?.applicant_name : "--"}</Space><br/><Space>{record?.pincode ? record?.pincode : "--"}</Space></span></div>
            ),
            ...getColumnSearchProps('applicant_name__icontains',"String"),
        },
        {
            title: 'Mobile / Email',
            key: 'mobileNo',
            render: (item, record) => (
                <span><Space>{record?.mobileNo ? truncateMobileNumber(record?.mobileNo)  : "--"}</Space><br/><Space>{record?.email ? record?.email : "--"}</Space></span>
            ),
            ...getColumnSearchProps('mobileNo__icontains',"String"),
        },

        {
            title: 'Salary Amount',
            key: 'annual_income',
            render: (item, record) => (
                <span>{record?.annual_income ? record?.annual_income : "--"}</span>
            ),
            ...getColumnSearchProps('annual_income__icontains',"DropDown")
        },

        {
            title: 'Desired Loan Amount',
            key: 'desired_loan_amount',
            render: (item, record) => (
                <span><Space>{record?.desired_loan_amount ? record?.desired_loan_amount : "--"}</Space><br/><Space>{record?.tenure ?  record?.tenure + " (In Years)" : "--"}</Space></span>
            ),
        },
        // {
        //     title: 'Loan Tenure (In Years)',
        //     key: 'tenure',
        //     render: (item, record) => (
        //         <span>{record?.tenure ? record?.tenure : "--"}</span>
        //     ),
        // },
        {
            title: 'Tata Status',
            key: 'tata_status',
            render:(item, record ,index) =>(
                <span>{record?.tata_status ?  <Tag color={"success"}>{record?.tata_status}</Tag> : record.mother_name? <Button type={"primary"} style={{borderRadius: 8}} onClick={() => tataChangeStatus(record)}>Update Satus</Button> : "--"}
                </span>
            ),


        },
        {
            title: 'L&T Status',
            key: 'l&t_status',
            render:(item, record,index) =>(
                <span>{record?.status ?<Tag color={"success"}>{record?.status}</Tag>   :record.mother_name? <Button type={"primary"} style={{borderRadius: 8}} onClick={() => lntChangeStatus(record)}>Update Satus</Button>:"--" }
                </span>
            ),

        },
        // {
        //     title: 'Application Id',
        //     key: 'applicationId',
        //     render: (item, record) => (
        //         <span>{record?.applicationId ? record?.applicationId : "--"}</span>
        //     ),
        // },
        {
            title: 'Status',
            key: 'status',
            render: (item, record) => (
                <Button type={"primary"} style={{borderRadius: 8}} onClick={() => excelUploadStatus(record)}>Get Status</Button>

            ),
        },
        // {
        //     title: 'Status Reason',
        //     key: 'errorDesc',
        //     render: (item, record) => (
        //         <span>{record?.errorDesc ? record?.errorDesc : "--"}</span>
        //     ),
        // },
        {
            title: 'Actions',
            key: 'actions',
            render: (item, record) => (


                <Button type={"primary"} style={{borderRadius: 8}} onClick={() => HandleModal(record)}>Details</Button>

            ),
        },
    ]
    return (

        <Card title={<Space><RangePicker
            allowClear={true}
            format={"DD/MM/YYYY"}
            onChange={onChangeDateRange}
            defaultValue={[startDate, endDate]}
        /></Space>}
              headStyle={{padding: 8}}
              bodyStyle={{padding: 8}}
              extra={
                  <Space>
                      <Button size={'medium'} disabled={false}
                              onClick={() => setIsModalOpen(true)}
                      >
                          <PlusOutlined/>Upload from Excel
                      </Button>
                      <Button style={{color: "green", borderColor: "green"}} icon={<FileExcelOutlined/>}
                              onClick={() => export_csv()}>Excel</Button>
                  </Space>
              }>
            <Spin spinning={loading}>
                <Table
                    dataSource={PersonalLoanList}
                    columns={columns}
                    pagination={false}
                />
            </Spin>

            <div style={{textAlign: 'center'}}>
                <CustomPagination
                    count={count}
                    next={nextPage}
                    previous={prevPage}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    current={current}
                    totalPage={totalPage}
                    onJump={handleJump}
                    pageLimit={20}
                />
            </div>
            <Modal title={'Bulk Upload Personal Loan'}
                   open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} width={700} destroyOnClose>
                <BulkUploadFile
                    setIsModalOpen={setIsModalOpen}
                    loadData={getPersonalLoan}
                    url={BULK_UPLOAD}
                    bulkFilePath={'/rpq-bulk-applicant.xlsx'}
                    successMSG={'Bulk Personal Loan Successfully Created'}
                />
            </Modal>
            <Modal width={1200} open={modalOpen} title={<Typography>Applicant's Details</Typography>}
                   footer={false} onCancel={() => setOpenModal(false)} destroyOnClose>
                <ApplicantDetails data={modalData}/>
            </Modal>

        </Card>

    )
}

export default ViewExcelPersonalLoanApplicant
